
export default {
	name: 'statistics',
	data() {
		return {
			count1: 0,
			count2: 0,
			count3: 0,
			count4: 0,
			count5: 0,
			x: [],
			y: [],
			token: '',
			userId: '',
			crcWorkDateStart: new Date(), //CRC工作量统计开始时间
			crcWorkDateEnd: new Date(), //CRC工作量统计结束时间
			projectWorkDateStart: new Date(), //项目工作量统计开始时间
			projectWorkDateEnd: new Date(), //项目工作量统计结束时间
		}
	},

	mounted() {
		this.drawLine();
		let echar3 = this.$echarts.init(document.getElementById('echar-3'));
		let echar4 = this.$echarts.init(document.getElementById('echar-4'));
		let echar5 = this.$echarts.init(document.getElementById('echar-5'));
		let echar6 = this.$echarts.init(document.getElementById('echar-6'));
		var _this = this;
		echar3.on('click', function (e) {
			//				console.log(e.name)
			_this.$store.commit('newResult', e.name);
			_this.$store.commit('getId', e.data.id);
			_this.$router.push('/officeList');
		})
		echar4.on('click', function (e) {
			_this.$store.commit('newResult', e.name);
			_this.$store.commit('getId', e.data.id);
			_this.$router.push('/crcProject')
		})
		echar5.on('click', function (e) {
			_this.$store.commit('newResult', e.name);
			_this.$store.commit('getId', e.data.id);
			_this.$router.push('/officeProject')
		})
		echar6.on('click', function (e) {
			_this.$store.commit('newResult', e.name);
			_this.$router.push('/mouthStatistics')
		})
	},
	created() {
		var _this = this;
		this.token = this.getCookie('token')
		this.userId = this.getCookie('userId')
		_this.crcWorkDateStart = _this.getCrcWorkDateEnd(_this.crcWorkDateStart)
		_this.projectWorkDateStart = _this.getCrcWorkDateEnd(_this.projectWorkDateStart)
		_this.getProjectsts1()
		_this.getProjectsts2()
		_this.getProjectsts3()
		_this.getProjectsts4()
		_this.getProjectsts5()
	},
	beforeRouteLeave(to, from, next) {
		to.meta.keepAlive = false;
		next();
	},
	methods: {
		//获取系统前一个月的时间
		getCrcWorkDateEnd(date) {
			date.setMonth(date.getMonth() - 2);
			var y = date.getFullYear();
			var m = date.getMonth() + 1;
			var d = date.getDate();
			var h = date.getHours();
			var minute = date.getMinutes();
			minute = minute < 10 ? ('0' + minute) : minute;
			var second = date.getSeconds();
			second = minute < 10 ? ('0' + second) : second;
			//				date = y + '-' + m-1 + '-' + d;
			date = new Date(y, m, d, h, minute, second)
			return date
		},
		getProjectsts1() {
			var _this = this;
			_this.$http.post('/API/PLAT/depCount', {}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.count1 = response.data.data;
				}
			})
		},
		getProjectsts2() {
			var _this = this;
			_this.$http.post('/API/PLAT/proCount', {}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.count2 = response.data.data;
				}
			})
		},
		getProjectsts3() {
			var _this = this;
			_this.$http.post('/API/PLAT/bidCount', {}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.count3 = response.data.data;
				}
			})
		},
		getProjectsts4() {
			var _this = this;
			_this.$http.post('/API/PLAT/smoCount', {}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.count4 = response.data.data;
				}
			})
		},
		getProjectsts5() {
			var _this = this;
			_this.$http.post('/API/PLAT/crcCount', {}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.count5 = response.data.data;
				}
			})
		},
		changeEchar1(start, end) {
			var _this = this;
			_this.$http.post('/API/PLAT/findAllCrcJobNumUp8', {
				startTime: start,
				endTime: end
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.getEchar1(response.data.data)
				}
			})
		},
		changeEchar2(start, end) {
			var _this = this;
			_this.$http.post('/API/PLAT/findAllProJectJobNumUp8', {
				startTime: start,
				endTime: end
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.getEchar2(response.data.data)
				}
			})
		},
		getEchar1(data) {
			let echar1 = this.$echarts.init(document.getElementById('echar-1'))
			echar1.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
				},
				legend: {
					data: ['工作量', '超窗次数']
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: data.x,
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}
				},
				yAxis: {
					type: 'value',
					min: 0,
					max: 20,
					interval: 5,
					boundaryGap: [0, 0.01],
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}
				},
				series: [{
					name: '工作量',
					type: 'bar',
					barWidth: '25%',
					color: '#61C7D7',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y1
				},
				{
					name: '超窗次数',
					type: 'bar',
					barWidth: '25%',
					color: '#FFE188',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y2
				}
				]
			});
		},
		getEchar2(data) {
			let echar2 = this.$echarts.init(document.getElementById('echar-2'))
			echar2.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
				},
				legend: {
					data: ['工作量', '超窗次数']
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: data.x,
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}
				},
				yAxis: {
					type: 'value',
					min: 0,
					max: 40,
					interval: 10,
					boundaryGap: [0, 0.01],
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}
				},
				series: [{
					name: '超窗次数',
					type: 'bar',
					barWidth: '25%',
					color: '#FFE188',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y1
				}, {
					name: '工作量',
					type: 'bar',
					barWidth: '25%',
					color: '#61C7D7',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y2
				}]
			});
		},
		drawLine() {
			var _this = this;
			// 基于准备好的dom，初始化echarts实例
			let echar2 = this.$echarts.init(document.getElementById('echar-2'))
			let echar3 = this.$echarts.init(document.getElementById('echar-3'))
			let echar4 = this.$echarts.init(document.getElementById('echar-4'))
			let echar5 = this.$echarts.init(document.getElementById('echar-5'))
			let echar6 = this.$echarts.init(document.getElementById('echar-6'))
			// 绘制图表
			_this.$http.post('/API/PLAT/findAllCrcJobNumUp8', {
				startTime: _this.crcWorkDateStart,
				endTime: _this.crcWorkDateEnd
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.getEchar1(response.data.data)
				}
			})
			// 绘制图表
			_this.$http.post('/API/PLAT/findAllProJectJobNumUp8', {
				startTime: _this.projectWorkDateStart,
				endTime: _this.projectWorkDateEnd
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.getEchar2(response.data.data)
				}
			})
			// 绘制图表
			_this.$http.post('/API/PLAT/inCountACP', {}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						_this.x.push(response.data.data.x);
					//						_this.y.push(response.data.data.y)
					//						console.log(response)
					echar3.setOption({
						tooltip: {
							trigger: 'axis',
							axisPointer: { // 坐标轴指示器，坐标轴触发有效
								type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
							}
						},
						legend: {
							data: ['CRC人数']
						},
						grid: {
							left: '3%',
							right: '4%',
							bottom: '3%',
							containLabel: true
						},
						xAxis: {
							type: 'value',
							min: 0,
							max: 80,
							interval: 10,
							axisLine: {
								lineStyle: {
									color: '##CBD1DA'
								}
							}
						},
						yAxis: {
							type: 'category',
							data: response.data.data.y,
							axisLine: {
								lineStyle: {
									color: '##CBD1DA'
								}
							},
							axisLabel: {
								interval: 0,
							}
						},
						series: [{
							name: 'CRC人数',
							type: 'bar',
							stack: '总量',
							barWidth: '70%',
							color: '#539CF2',
							label: {
								normal: {
									show: true,
									position: 'insideRight'
								}
							},
							data: response.data.data.x
						}]
					});
				}
			})

			// 绘制图表
			_this.$http.post('/API/PLAT/inCountBCP', {}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					echar4.setOption({
						tooltip: {
							trigger: 'axis',
							axisPointer: { // 坐标轴指示器，坐标轴触发有效
								type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
							}
						},
						legend: {
							data: ['项目数']
						},
						grid: {
							left: '3%',
							right: '4%',
							bottom: '3%',
							containLabel: true
						},
						xAxis: {
							type: 'value',
							min: 0,
							max: 20,
							interval: 2,
							axisLine: {
								lineStyle: {
									color: '##CBD1DA'
								}
							}
						},
						yAxis: {
							type: 'category',
							data: response.data.data.y,
							axisLine: {
								lineStyle: {
									color: '##CBD1DA'
								}
							}
						},
						series: [{
							name: '项目数',
							type: 'bar',
							barWidth: '70%',
							color: '#DBECFE',
							label: {
								normal: {
									show: true,
									position: 'insideRight'
								}
							},
							data: response.data.data.x
						}]
					});
				}
			})

			// 绘制图表
			_this.$http.post('/API/PLAT/inCountDepartment', {}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					echar5.setOption({
						tooltip: {
							trigger: 'axis',
							axisPointer: { // 坐标轴指示器，坐标轴触发有效
								type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
							}
						},
						legend: {
							data: ['人员数', '项目数']
						},
						grid: {
							left: '3%',
							right: '4%',
							bottom: '3%',
							containLabel: true
						},
						xAxis: {
							type: 'category',
							data: response.data.data.x,
							axisLine: {
								lineStyle: {
									color: '##CBD1DA'
								}
							}
						},
						yAxis: {
							type: 'value',
							min: 0,
							max: 40,
							interval: 10,
							boundaryGap: [0, 0.01],
							axisLine: {
								lineStyle: {
									color: '##CBD1DA'
								}
							}
						},
						series: [{
							name: '人员数',
							type: 'bar',
							barWidth: '25%',
							color: '#61C7D7',
							label: {
								normal: {
									show: true,
									position: 'insideRight'
								}
							},
							data: response.data.data.y1
						},
						{
							name: '项目数',
							type: 'bar',
							barWidth: '25%',
							color: '#FFE188',
							label: {
								normal: {
									show: true,
									position: 'insideRight'
								}
							},
							data: response.data.data.y2
						}
						]
					});
				}
			})
			// 绘制图表
			_this.$http.post('/API/PLAT/inCountDCP', {}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					echar6.setOption({
						tooltip: {
							trigger: 'axis',
							axisPointer: { // 坐标轴指示器，坐标轴触发有效
								type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
							}
						},
						legend: {
							data: ['项目数']
						},
						grid: {
							left: '3%',
							right: '4%',
							bottom: '3%',
							containLabel: true
						},
						xAxis: {
							type: 'category',
							data: response.data.data.x3,
							axisLine: {
								lineStyle: {
									color: '##CBD1DA'
								}
							}
						},
						yAxis: {
							type: 'value',
							min: 0,
							max: 40,
							interval: 10,
							boundaryGap: [0, 0.01],
							axisLine: {
								lineStyle: {
									color: '##CBD1DA'
								}
							}
						},
						series: [{
							name: '项目数',
							type: 'bar',
							barWidth: '50%',
							color: '#61C7D7',
							label: {
								normal: {
									show: true,
									position: 'insideRight'
								}
							},
							data: response.data.data.x2
						}]
					});
				}
			})
		},
		toOfficeList() {
			this.$router.push('/statistics1');
		},
		toOfficeProject() {
			this.$router.push('/statistics3')
		},
		toCrcProject() {
			this.$router.push('/statistics2')
		},
		toMouthStatistics() {
			this.$router.push('/mouthStatistics')
			this.$store.commit('newResult', '');
		},
		toProjectWork() {
			this.$router.push('/projectWorkList')
		},
		toCrcWork() {
			this.$router.push('/crcWorkList')
		}
	}
}

